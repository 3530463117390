<script lang="ts">
	export let height = '48px' // Default height
	export let width = '48px' // Default width
	let className = ''
	export { className as class }
</script>

<svg
	xmlns="http://www.w3.org/2000/svg"
	fill="none"
	viewBox="0 0 48 48"
	{height}
	{width}
	class={className}
>
	<path
		d="M11.064 30.12C9.89552 28.949 9.89552 27.053 11.064 25.882L8 22.818C7.219 22.0372 5.953 22.0372 5.172 22.818L1.086 26.904C0.305236 27.685 0.305236 28.951 1.086 29.732L10.672 39.318C11.453 40.0988 12.719 40.0988 13.5 39.318L16.528 36.292C16.622 36.1982 16.6749 36.0708 16.6749 35.938C16.6749 35.8052 16.622 35.6778 16.528 35.584L11.064 30.12Z"
		fill="currentColor"
	/>
	<path
		d="M19.4 21.8L22.424 24.822C22.5178 24.916 22.6452 24.9689 22.778 24.9689C22.9108 24.9689 23.0382 24.916 23.132 24.822L35.856 12.098C35.95 12.0042 36.0029 11.8768 36.0029 11.744C36.0029 11.6112 35.95 11.4838 35.856 11.39L33.142 8.676C32.361 7.89523 31.095 7.89523 30.314 8.676L17.97 21.02C18.5116 21.1375 19.008 21.4083 19.4 21.8V21.8Z"
		fill="currentColor"
	/>
	<path
		d="M43.122 9.062C42.7315 8.67162 42.0985 8.67162 41.708 9.062L23.478 27.292C23.0875 27.6824 22.4545 27.6824 22.064 27.292L17.978 23.2C17.5875 22.8096 16.9545 22.8096 16.564 23.2L12.478 27.292C12.0876 27.6825 12.0876 28.3155 12.478 28.706L22.064 38.292C22.4545 38.6824 23.0875 38.6824 23.478 38.292L47.2 14.564C47.5904 14.1735 47.5904 13.5405 47.2 13.15L43.122 9.062Z"
		fill="currentColor"
	/>
</svg>
