<script lang="ts">
	import { pluralize } from '$lib/utils/text-helpers'
	import CheckIcon from '../icons/CheckIcon.svelte'
	import FlagIcon from '../icons/FlagIcon.svelte'
	import HighlightIcon from '../icons/HighlightIcon.svelte'
	import LinkChainIcon from '../icons/LinkChainIcon.svelte'
	import MagicIcon from '../icons/MagicIcon.svelte'
	import PlacePinIcon from '../icons/PlacePinIcon.svelte'

	export let numBlocks: number = 0
	export let numDestinations: number = 0
	export let numHighlights: number = 0
	export let numSources: number = 0
	export let isLightOverride: boolean = false
	export let isCompact: boolean = false
</script>

{#if isCompact}
	<div class="flex flex-row space-x-2">
		{#if numDestinations > 0}
			<div class="flex items-center">
				<FlagIcon class={`h-3 w-3 ${isLightOverride ? 'text-black' : 'dark:text-brand-gray-3'}`} />
				<span
					class={`text-xs text-brand-gray-2 ml-1 ${
						isLightOverride ? 'text-brand-gray-5' : 'dark:text-brand-gray-3'
					}`}
				>
					{numDestinations}
				</span>
			</div>
		{/if}
		{#if numBlocks > 0}
			<div class="flex items-center">
				<PlacePinIcon
					class={`h-3 w-3 ${isLightOverride ? 'text-black' : 'dark:text-brand-gray-3'}`}
				/>
				<span
					class={`text-xs text-brand-gray-2 ml-1 ${
						isLightOverride ? 'text-brand-gray-5' : 'dark:text-brand-gray-3'
					}`}
				>
					{numBlocks}
				</span>
			</div>
		{/if}
		{#if numHighlights > 0}
			<div class="flex items-center">
				<HighlightIcon
					class={`h-3 w-3 ${isLightOverride ? 'text-black' : 'dark:text-brand-gray-3'}`}
				/>
				<span
					class={`text-xs text-brand-gray-2 ml-1 ${
						isLightOverride ? 'text-brand-gray-5' : 'dark:text-brand-gray-3'
					}`}
				>
					{numHighlights}
				</span>
			</div>
		{/if}
		{#if numSources > 0}
			<div class="flex items-center">
				<LinkChainIcon
					class={`h-3 w-3 ${isLightOverride ? 'text-black' : 'dark:text-brand-gray-3'}`}
				/>
				<span
					class={`text-xs text-brand-gray-2 ml-1 ${
						isLightOverride ? 'text-brand-gray-5' : 'dark:text-brand-gray-3'
					}`}
				>
					{numSources}
				</span>
			</div>
		{/if}
	</div>
{:else}
	<div class="flex flex-col w-full space-y-3 mt-3 mb-5">
		{#if numDestinations > 0}
			<div class="flex items-center justify-start w-full">
				<FlagIcon class={`h-4 w-4 ml-1 ${isLightOverride ? 'text-black' : 'dark:text-white'}`} />
				<p
					class={`text-xs text-brand-gray-2 px-2 ${
						isLightOverride ? 'text-brand-gray-5' : 'dark:text-brand-gray-2'
					}`}
				>
					{pluralize(numDestinations, 'Destination', 'Destinations')}
				</p>
			</div>
		{/if}
		{#if numBlocks > 0}
			<div class="flex items-center justify-start w-full">
				<PlacePinIcon
					class={`h-4 w-4 ml-1 ${isLightOverride ? 'text-black' : 'dark:text-white'}`}
				/>
				<p
					class={`text-xs text-brand-gray-2 px-2 ${
						isLightOverride ? 'text-brand-gray-5' : 'dark:text-brand-gray-2'
					}`}
				>
					{pluralize(numBlocks, 'Recommendation', 'Recommendations')}
				</p>
			</div>
		{/if}
		{#if numHighlights > 0}
			<div class="flex items-center justify-start w-full">
				<HighlightIcon
					class={`h-4 w-4 ml-1 ${isLightOverride ? 'text-black' : 'dark:text-white'}`}
				/>
				<p
					class={`text-xs text-brand-gray-2 px-2 ${
						isLightOverride ? 'text-brand-gray-5' : 'dark:text-brand-gray-2'
					}`}
				>
					{pluralize(numHighlights, 'Image or Video', 'Images or Videos')}
				</p>
			</div>
		{/if}
		{#if numSources > 0}
			<div class="flex items-center justify-start w-full">
				<LinkChainIcon
					class={`h-4 w-4 ml-1 ${isLightOverride ? 'text-black' : 'dark:text-white'}`}
				/>
				<p
					class={`text-xs text-brand-gray-2 px-2 ${
						isLightOverride ? 'text-brand-gray-5' : 'dark:text-brand-gray-2'
					}`}
				>
					{pluralize(numSources, 'Link', 'Links')}
				</p>
			</div>
		{/if}

		<div class="flex items-center justify-start w-full">
			<CheckIcon class={`h-4 w-4 ml-1 ${isLightOverride ? 'text-black' : 'dark:text-white'}`} />
			<p
				class={`text-xs text-brand-gray-2 px-2 ${
					isLightOverride ? 'text-brand-gray-5' : 'dark:text-brand-gray-2'
				}`}
			>
				Customize & Build Itineraries
			</p>
		</div>

		<div class="flex items-center justify-start w-full">
			<MagicIcon class={`h-4 w-4 ml-1 ${isLightOverride ? 'text-black' : 'dark:text-white'}`} />
			<p
				class={`text-xs text-brand-gray-2 px-2 ${
					isLightOverride ? 'text-brand-gray-5' : 'dark:text-brand-gray-2'
				}`}
			>
				Travel Data: Requirements, Safety Scores, Routing
			</p>
		</div>
	</div>
{/if}
